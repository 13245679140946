const TIMEOUT = 2000;
export function onClick(parent) {
    const input = parent.querySelector('input');
    if (navigator.clipboard) {
        navigator.clipboard.writeText(input.value)
            .then(() => showClicked(parent));
    }
    else {
        input.select();
        document.execCommand('copy');
        showClicked(parent);
    }
}
function showClicked(el) {
    el.classList.add('clicked');
    if (el.dataset.timeout) {
        clearTimeout(el.dataset.timeout);
    }
    el.dataset.timeout = setTimeout(() => {
        el.classList.remove('clicked');
        delete el.dataset.timeout;
    }, TIMEOUT).toString();
}
export default function initClickToCopy(selector) {
    document.querySelectorAll(selector)
        .forEach(copyBox => copyBox.addEventListener('click', () => onClick(copyBox)));
}
