import { StatusClassNames } from "../types/StatusClassNames";
const rootElement = document.querySelector('.bottom-nav');
export default function initMobileNavigation() {
    if (!rootElement) {
        return;
    }
    const buttons = rootElement.querySelectorAll('.bottom-link');
    if (!buttons.length) {
        return;
    }
    buttons.forEach(button => {
        const isAnchor = button.tagName === 'A';
        const targetPane = rootElement.querySelector(button.dataset.target);
        const closeButton = targetPane === null || targetPane === void 0 ? void 0 : targetPane.querySelector('.nav-box__close button');
        if (button.classList.contains(StatusClassNames.active)) {
            button.classList.add(StatusClassNames.fixed);
        }
        button.addEventListener('click', function (event) {
            const isDeactivation = isAnchor || (this.classList.contains(StatusClassNames.active) && (targetPane ? targetPane.open : true));
            resetHighlights();
            rootElement.querySelectorAll('dialog[open]')
                .forEach(dialog => closePane(dialog));
            if (!targetPane) {
                return;
            }
            if (isDeactivation) {
                closePane(targetPane);
                return;
            }
            // close opened dialogs
            event.preventDefault();
            button.classList.add(StatusClassNames.active);
            document.body.classList.add(StatusClassNames.noscroll);
            targetPane.show();
        });
        if (!closeButton) {
            return;
        }
        closeButton.addEventListener('click', () => closePane(targetPane));
    });
}
function resetHighlights() {
    rootElement.querySelectorAll(`.bottom-link.${StatusClassNames.active}:not(.${StatusClassNames.fixed})`)
        .forEach(button => button.classList.remove(StatusClassNames.active));
}
function closePane(pane) {
    pane.close();
    resetHighlights();
    document.body.classList.remove(StatusClassNames.noscroll);
}
