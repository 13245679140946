import { isFullyVisible } from "../utils";
const ITEM_SELECTOR = '.search-result__item';
export default class SearchResultKeys {
    constructor(input, results, itemSelector = ITEM_SELECTOR, onSelect) {
        this.results = results;
        this.itemSelector = itemSelector;
        this.onSelect = onSelect;
        input.addEventListener("keyup", this.isHandledKeyPressed.bind(this));
        input.addEventListener("keypress", this.isHandledKeyPressed.bind(this));
        input.addEventListener("keydown", this.onKeyPress.bind(this));
    }
    isHandledKeyPressed(event) {
        if (!['ArrowDown', 'ArrowUp', 'Home', 'End', 'PageUp', 'PageDown', 'Enter', 'Escape'].includes(event.key)) {
            return false;
        }
        if (event.key === 'Enter') {
            if (this.getSelectedItem()) {
                event.preventDefault();
                event.stopPropagation();
            }
            if (event.type === 'keyup') {
                this.onSelect(this.count() === 1 ? this.getFirstItem() : this.getSelectedItem());
            }
            return true;
        }
        if (event.type === 'keydown') {
            this.validateSelection();
        }
        if (event.shiftKey || event.altKey || event.ctrlKey || event.metaKey) {
            return false;
        }
        if (this.isEmpty()) {
            return false;
        }
        event.preventDefault();
        event.stopPropagation();
        return true;
    }
    onKeyPress(event) {
        if (!this.isHandledKeyPressed(event)) {
            this.deselect();
            if (this.results.firstElementChild.scrollTop) {
                this.results.firstElementChild.scrollTo({ top: 0, behavior: 'auto' });
            }
            return;
        }
        this.select(this.parseKey(this.getSelectedItem(), event.key));
    }
    parseKey(item, key) {
        var _a, _b;
        switch (key) {
            case 'ArrowDown':
                return (_a = this.getNextItem()) !== null && _a !== void 0 ? _a : this.getLastItem();
            case 'ArrowUp':
                return (_b = this.getPreviousItem()) !== null && _b !== void 0 ? _b : this.getFirstItem();
            case 'Home':
                return this.getFirstItem();
            case 'End':
                return this.getLastItem();
            case 'PageUp':
                return this.multistep(item !== null && item !== void 0 ? item : this.getLastItem(), this.calculateNumberOfLines(), true);
            case 'PageDown':
                return this.multistep(item !== null && item !== void 0 ? item : this.getFirstItem(), this.calculateNumberOfLines());
            case 'Enter':
                return this.getSelectedItem();
        }
    }
    getSelectedItem() {
        return this.results.querySelector(`${this.itemSelector}[data-selected]`);
    }
    isEmpty() {
        return !this.getFirstItem();
    }
    getFirstItem() {
        return this.results.querySelector(this.itemSelector);
    }
    getLastItem() {
        return [...this.results.querySelectorAll(this.itemSelector)].pop();
    }
    getSelectedItems() {
        return this.results.querySelectorAll(`${this.itemSelector}[data-selected]`);
    }
    calculateNumberOfLines() {
        const item = this.getFirstItem();
        if (!item) {
            return 0;
        }
        return Math.floor(this.results.getBoundingClientRect().height / item.getBoundingClientRect().height);
    }
    select(item) {
        this.lastSelected = item;
        if (!item) {
            return false;
        }
        this.deselect(item);
        item.dataset.selected = '';
        this.scrollIntoView(item);
        return true;
    }
    multistep(start, steps, reverse = false) {
        let items = Array.from(this.results.querySelectorAll(this.itemSelector));
        let current;
        if (reverse) {
            items = items.reverse();
        }
        for (const item of items) {
            if (!current) {
                if (item === start) {
                    current = item;
                }
                continue;
            }
            if (steps--) {
                current = item;
            }
            else {
                break;
            }
        }
        return current !== null && current !== void 0 ? current : start;
    }
    scrollIntoView(item) {
        if (isFullyVisible(item, this.results)) {
            return;
        }
        const itemRect = item.getBoundingClientRect();
        const wrapRect = this.results.getBoundingClientRect();
        const diff = itemRect.bottom > wrapRect.bottom
            ? itemRect.bottom - wrapRect.bottom + itemRect.height * 0.4
            : itemRect.top - wrapRect.top - itemRect.height * 0.4;
        this.results.firstElementChild.scrollBy({
            top: diff,
            behavior: Math.abs(diff) > itemRect.height * 1.5 ? 'smooth' : 'auto',
        });
    }
    deselect(exclude) {
        let deselected = false;
        this.getSelectedItems().forEach((item) => {
            if (item !== exclude) {
                delete item.dataset.selected;
            }
            else {
                deselected = true;
            }
        });
        return deselected;
    }
    getNextItem() {
        const selected = this.getSelectedItem();
        if (!selected) {
            return this.getFirstItem();
        }
        let selectedItemFound = false;
        for (const element of this.results.querySelectorAll(this.itemSelector)) {
            if (selectedItemFound) {
                return element;
            }
            if (element === selected) {
                selectedItemFound = true;
            }
        }
        return selected ? null : this.getFirstItem();
    }
    getPreviousItem() {
        const selected = this.getSelectedItem();
        return selected ? selected.previousElementSibling : this.getLastItem();
    }
    count() {
        return this.results.querySelectorAll(this.itemSelector).length;
    }
    validateSelection() {
        this.deselect(this.lastSelected);
        if (this.lastSelected) {
            this.scrollIntoView(this.lastSelected);
        }
    }
}
