import { addGlobalLiveEventListener, toggleSiblingsClasses } from "../utils";
import { StatusClassNames } from "../types/StatusClassNames";
import { useDragToScroll } from "./drag_scroll";
import EventBus from "../events/EventBus";
export default function initTabs() {
    addGlobalLiveEventListener('click', '[data-tab-target]', function (e) {
        e.preventDefault();
        e.stopPropagation();
        if (this.classList.contains(StatusClassNames.active)) {
            return;
        }
        const target = findTargetTab(this.dataset.tabTarget);
        if (!target) {
            return;
        }
        toggleSiblingsClasses(target, StatusClassNames.active, '.tab-content');
        toggleSiblingsClasses(this, StatusClassNames.active, '[data-tab-target]', '.tabs');
    });
    for (const el of document.querySelectorAll('.tabs__wrap')) {
        useDragToScroll(el);
    }
    EventBus.on('modal:open', modal => {
        for (const el of modal.querySelectorAll('.tabs__wrap')) {
            useDragToScroll(el);
        }
    });
}
function findTargetTab(id) {
    for (const el of document.querySelectorAll(`#${id}`)) {
        if (el.parentElement.scrollWidth) {
            return el;
        }
    }
}
