// сердечко на товарах
import { addGlobalLiveEventListener, extractData, getFetchOptions, startIconLoading, stopIconLoading } from "../../utils";
import ajax from "../ajax";
import { StatusClassNames } from "../../types/StatusClassNames";
import EventBus from "../../events/EventBus";
import { showModalFromHtml } from "../modal";
let list = [];
export function highlightFavorites() {
    if (!list.length) {
        return;
    }
    for (const el of document.querySelectorAll(`.favorite-button`)) {
        if (list.includes(parseInt(el.dataset.id))) {
            el.classList.add(StatusClassNames.filled);
        }
        else {
            el.classList.remove(StatusClassNames.filled);
        }
    }
}
export function initFavorites() {
    var _a;
    const dataEl = document.getElementById('favorites-data');
    if (dataEl) {
        list = (_a = extractData(dataEl)) !== null && _a !== void 0 ? _a : [];
        highlightFavorites();
    }
}
addGlobalLiveEventListener('click', '.favorite-button', function (e) {
    const id = parseInt(this.dataset.id);
    e.preventDefault();
    startIconLoading(this);
    ajax('/ajax/favourite', { id })
        .then(response => response.json())
        .then(response => {
        if (list.includes(id)) {
            this.classList.remove(StatusClassNames.filled);
            delete list[list.indexOf(id)];
        }
        else {
            if (response.html) {
                showModalFromHtml(response.html);
            }
            this.classList.add(StatusClassNames.filled);
            list.push(id);
            window.vkEvent(process.env.VK_PRICE_LIST_ID, 'add_to_wishlist', { products: [{ id: id }] });
        }
    })
        .finally(() => stopIconLoading(this));
});
highlightFavorites();
function onChange(event) {
    const checkbox = event.target;
    const fieldset = checkbox.closest('fieldset');
    fieldset.disabled = true;
    const body = {
        id: parseInt(checkbox.value),
        shelf: checkbox.name,
        remove: !checkbox.checked,
    };
    fetch('/api/wardrobe/append', getFetchOptions(body, true))
        .finally(() => fieldset.disabled = false);
}
EventBus.on('products:load', highlightFavorites);
EventBus.on('modal:open', modal => {
    if (modal.id === 'modal-add-to-favourites') {
        modal.querySelectorAll('input[type=checkbox]')
            .forEach(checkbox => checkbox.addEventListener('change', onChange));
    }
});
EventBus.on('modal:close', modal => {
    if (modal.id === 'modal-add-to-favourites') {
        modal.querySelectorAll('input[type=checkbox]')
            .forEach(checkbox => checkbox.removeEventListener('change', onChange));
    }
});
