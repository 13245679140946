import { StatusClassNames } from "../types/StatusClassNames";
import { toggleSiblingsClasses } from "../utils";
function init(tabsParent) {
    tabsParent.querySelectorAll('.tab-link').forEach(el => {
        el.addEventListener('click', e => {
            e.preventDefault();
            onClick(el);
            history.pushState(el.dataset.code, `${el.innerText} бренды`, el.href);
        });
    });
    window.addEventListener('popstate', event => activateByCode(event.state));
    document.addEventListener('DOMContentLoaded', activateActiveElement);
    function activateByCode(code) {
        if (code === null) {
            code = 'ALL';
        }
        for (const el of Array.from(tabsParent.querySelectorAll('.tab-link'))) {
            if (el.dataset.code !== code) {
                continue;
            }
            onClick(el);
            break;
        }
    }
    function activateActiveElement() {
        for (const el of Array.from(tabsParent.querySelectorAll('.tab-link'))) {
            if (!el.classList.contains(StatusClassNames.active)) {
                continue;
            }
            onClick(el, true);
            break;
        }
    }
    function onClick(el, force = false) {
        if (el.classList.contains(StatusClassNames.active) && !force) {
            return;
        }
        toggleSiblingsClasses(el, StatusClassNames.active, '.tab-link', tabsParent);
        if (el.dataset.code === 'ALL') {
            reset();
        }
        else {
            updateCategory(el.dataset.code);
        }
    }
    function updateCategory(category) {
        for (const el of Array.from(document.querySelectorAll(`.abc-nav__item:not([data-category~="${category}"]):not(.hidden)`))) {
            el.classList.add('hidden');
        }
        for (const el of Array.from(document.querySelectorAll(`.abc-nav__item.hidden[data-category~="${category}"]`))) {
            el.classList.remove('hidden');
        }
        updateLettersVisibility();
    }
    function reset() {
        for (const el of Array.from(document.querySelectorAll('.abc-nav__item.hidden'))) {
            el.classList.remove('hidden');
        }
        updateLettersVisibility();
    }
    function updateLettersVisibility() {
        for (const letterBlock of Array.from(document.querySelectorAll('.abc-nav'))) {
            const letter = letterBlock.querySelector('.abc-nav__letter').innerText.trim();
            const letterLink = document.querySelector(`.letters-nav__link[href="#${letter}"]`);
            if (letterBlock.querySelector('.abc-nav__item:not(.hidden)')) {
                letterBlock.classList.remove('hidden');
                letterLink.classList.remove('disabled');
            }
            else {
                letterBlock.classList.add('hidden');
                letterLink.classList.add('disabled');
            }
        }
    }
}
const filter = document.getElementById('brands_filter');
if (filter) {
    init(filter);
}
