import { extractAppData } from "../utils";
import { isEqual } from "lodash";
import EventBus from "../events/EventBus";
const cartElement = document.querySelector('.basket_head');
const cartBottomLink = document.getElementById('bottom_link_cart');
let offers = [];
updateCartOffers();
export function updateCartFromHTML(html) {
    cartElement.innerHTML = html;
    updateCartOffers();
}
export function updateCartOffers() {
    if (!cartElement) {
        return;
    }
    const newOffers = extractAppData(cartElement);
    if (newOffers && Array.isArray(newOffers) && isEqual(newOffers, offers)) {
        return;
    }
    offers = newOffers;
    EventBus.emit('cart:update', offers);
    if (offers.length) {
        cartBottomLink.classList.add('bottom-link--has-new');
    }
    else {
        cartBottomLink.classList.remove('bottom-link--has-new');
    }
}
export function getCartOffersList() {
    return offers;
}
